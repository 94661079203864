



















































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { dispatchGetUserById, dispatchUpdateUser } from '@/store/crud/actions';
import StateSelector from '@/components/modals/StateSelectorModal.vue';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import AppComponent from '@/mixins/ComponentMixin.vue';
import { getStatetextByvalue } from '@/constants/listOptions';
import { IUserProfileModel, IUserProfileUpdate } from '@/interfaces/users';
import { ILicenseState } from '@/interfaces/licenceState';


@Component({ components: { VuePhoneNumberInput, StateSelector } })
export default class EditUser extends AppComponent {
  @Prop({ type: String }) public id: string;

  public valid = true;
  public firstName: string = '';
  public lastName: string = '';
  public email: string = '';
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public isPhysician: boolean = false;
  public healthGorillaId: string = '';
  public isNurse: boolean = false;
  public isReviewer: boolean = false;
  public isCreator: boolean = false;

  public npi: string = '';
  public setPassword = false;
  public password1: string = '';
  public password2: string = '';
  public phoneNumberModel: any = '';
  public phoneNumber: string | undefined = '';
  public assignedStates: any[] = [];
  public validPhoneNumber: boolean = true;

  public userProfile: IUserProfileModel = {};

  public mounted() {
    this.initScreen();
  }

  /**
   * inits the screen asyncronously
   */
  public async initScreen() {
    if (this.id !== undefined && this.id.length > 0) {
      await this.fetchData();
      if (this.userProfile) {
        this.reset();
      } else {
        this.$router.back();
      }
    } else {
      this.$router.back();
    }

  }

  /**
   * fetches data from the store and show a loading screen
   */
  public async fetchData() {
    this.setAppLoading(true);
    this.userProfile = await dispatchGetUserById(this.$store, this.id);
    this.setAppLoading(false);
  }

  public reset() {
    this.setPassword = false;
    this.password1 = '';
    this.password2 = '';
    this.$validator.reset();

    if (this.userProfile) {
      this.firstName = this.userProfile.fname || '';
      this.lastName = this.userProfile.lname || '';
      this.phoneNumber = this.userProfile.phoneNumber ? this.userProfile.phoneNumber : '';
      this.phoneNumberModel = this.userProfile.phoneNumber ? this.userProfile.phoneNumber : '';
      this.email = this.userProfile.email || '';
      this.isActive = this.userProfile.isActive || false;
      this.isSuperuser = this.userProfile.isSuperuser || false;
      this.isPhysician = this.userProfile.isPhysician || false;
      this.isNurse = this.userProfile.isNursePracticioner || false;
      this.isReviewer = this.userProfile.isReviewer || false;
      this.isCreator = this.userProfile.isCreator || false;
      if (this.isPhysician || this.isNurse) {
        this.healthGorillaId = this.userProfile.healthGorillaId || '';
        this.npi = this.userProfile.npi || '';
        if (this.userProfile.licenseState) {
          for (const state of this.userProfile.licenseState) {
              this.assignedStates.push({
                state: state.licenseState,
                licenseNumber: state.licenseNumber,
              });
          }
        }
      }
    }
  }

  @Watch('isSuperuser') public onIsSuperuserChange(newVal: boolean) {
    if (newVal) {
      this.isNurse = false;
      this.isReviewer = false;
      this.isCreator = false;
    }
  }

  @Watch('isPhysician') public onisPhysicianChange(newVal: boolean) {
    if (newVal) {
      this.isNurse = false;
      this.isReviewer = false;
      this.isCreator = false;
    }
  }

  @Watch('isNurse') public onIsNurseChange(newVal: boolean) {
    if (newVal) {
      this.isPhysician = false;
      this.isSuperuser = false;
      this.isReviewer = false;
      this.isCreator = false;
    }
  }

  @Watch('isReviewer') public onIsReviewerChange(newVal: boolean) {
    if (newVal) {
      this.isPhysician = false;
      this.isSuperuser = false;
      this.isNurse = false;
      this.isCreator = false;
    }
  }

  @Watch('isCreator') public onIsCreatorChange(newVal) {
    if (newVal) {
      this.isPhysician = false;
      this.isSuperuser = false;
      this.isNurse = false;
      this.isReviewer = false;
    }
  }

  public cancel() {
    this.$router.back();
  }


  public setStates(targetState) {
    if (!this.assignedStates) {
      this.assignedStates = [];
    }
    this.assignedStates.push({
      state: targetState.state.value,
      licenseNumber: targetState.licenseNumber,
    });
  }

  public removeState(index) {
    this.assignedStates.splice(index, 1);
  }

  public getStateName(state) {
    return getStatetextByvalue(state);
  }

  /*
    It checks if the phone number is correct
  */
  public checkPhoneNumber(event: any) {
    if (event.isValid) {
      this.phoneNumber = event.formattedNumber;
      this.validPhoneNumber = true;
    } else {
      if (!event.formattedNumber) {
        this.validPhoneNumber = true; // empty is valid
      } else {
        this.validPhoneNumber = false;
      }
      this.phoneNumber = '';
    }
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const protoEditUser: IUserProfileModel = {};

      /* Sanitize values and set only modified data */
      this.setFieldIfChanges(protoEditUser, 'fname', this.firstName, this.userProfile.fname);
      this.setFieldIfChanges(protoEditUser, 'lname', this.lastName, this.userProfile.lname);
      this.setFieldIfChanges(protoEditUser, 'email', this.email, this.userProfile.email);
      this.setFieldIfChanges(protoEditUser, 'phoneNumber', this.phoneNumber, this.userProfile.phoneNumber);
      this.setFieldIfChanges(protoEditUser, 'isActive', this.isActive, this.userProfile.isActive);
      this.setFieldIfChanges(protoEditUser, 'isSuperuser', this.isSuperuser, this.userProfile.isSuperuser);
      this.setFieldIfChanges(protoEditUser, 'isPhysician', this.isPhysician, this.userProfile.isPhysician);
      this.setFieldIfChanges(protoEditUser, 'isReviewer', this.isReviewer, this.userProfile.isReviewer);
      this.setFieldIfChanges(protoEditUser, 'isCreator', this.isCreator, this.userProfile.isCreator);
      this.setFieldIfChanges(protoEditUser, 'isNursePracticioner', this.isNurse, this.userProfile.isNursePracticioner);
      this.setFieldIfChanges(protoEditUser, 'npi', this.npi, this.userProfile.npi);

      if (this.assignedStates) {
        const licenseState: any = [];
        // we create the list of licenses from the array of states and licenses
        for (const state of this.assignedStates) {
          if (state && state.licenseNumber) {
            const currentLicense: ILicenseState = {
              licenseState: state.state,
              licenseNumber: state.licenseNumber,
            };
            licenseState.push(currentLicense);
          }
        }
        this.setFieldIfChanges(protoEditUser, 'licenseState', licenseState, this.userProfile.licenseState);
      }

      if (this.isPhysician) {
        this.setFieldIfChanges(
          protoEditUser, 'healthGorillaId', this.healthGorillaId, this.userProfile.healthGorillaId);
      }

      if (this.setPassword) {
        if (this.hasValue(this.password1)) { protoEditUser.password = this.password1; }
      }

      const updateUser: IUserProfileUpdate = protoEditUser as IUserProfileUpdate;
      if (!this.isEmpty(updateUser)) {
        await dispatchUpdateUser(this.$store, {
          id: this.id,
          user: updateUser,
        });
        this.$router.push('/main/admin/users');
      }
    }
  }

}
